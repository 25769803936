<template>
  <div :style="{ height: height, overflow: 'auto', 'padding-bottom': '25px' }">
    <!-- <van-nav-bar
      title="我的任务服务费"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    ></van-nav-bar> -->
    <div class="topBox">
      <div class="title">
        {{ $store.state.auditDetailsItem.terminalName }}
      </div>
      <div class="content">
        {{ $store.state.auditDetailsItem.taskName }}:
        {{ $store.state.auditDetailsItem.taskSubName }}
      </div>
      <div class="content">提交时间: {{ computedTime($store.state.auditDetailsItem.reportTime) }}</div>
      <div class="content" v-if="$store.state.auditDetailsItem.auditTime">
        审核时间: {{ computedTime($store.state.auditDetailsItem.auditTime) }}
      </div>
      <div class="content" v-if="$store.state.auditDetailsItem.auditReason != null">
        审核原因: {{ $store.state.auditDetailsItem.auditReason }}
      </div>
      <!--  :style="{ color: tipsColor($store.state.auditDetailsItem.auditStatus) }" -->
      <div class="tips">
        {{ computedStatus($store.state.auditDetailsItem.auditStatus) }}
      </div>
    </div>
    <div>
      <div class="card" v-for="(item, index) in dataList" :key="index">
        <div class="title">{{ index + 1 }}、{{ item.itemTitle }}</div>
        <div class="container">
          <div v-if="item.itemType == 0 || item.itemType == 5">
            <div class="warning">答 :</div>
            <div style="min-height: 21px; line-height: 21px">{{ item.answer }}</div>
          </div>
          <div v-else-if="item.itemType == 1">
            <div class="warning">答 :</div>
            <div style="min-height: 21px; line-height: 21px">{{ item.option }}</div>
          </div>
          <div v-else-if="item.itemType == 2">
            <div class="warning">答 :</div>
            <div style="min-height: 21px; line-height: 21px">
              <div v-for="data in item.optionList" :key="data">{{ data }}</div>
            </div>
          </div>
          <div v-else-if="item.itemType == 3">
            <van-image
              class="image"
              fit="contain"
              v-for="(data, index) in item.imgList"
              :key="index"
              :src="data"
              @click="preImg(data)"
            />
            <div style="margin-top: 10px" v-if="item.sampleImg">
              正确样例 :
              <span style="color: #6689b6" @click="preImg(item.sampleImg)">查看</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btnBox">
      <van-button type="default" class="fir" @click="onClickLeft"> 返回 </van-button>
    </div>
  </div>
</template>
<script>
import { myTaskDetail } from '@/api/api.js'
import { ImagePreview } from 'vant'
import moment from 'moment'
export default {
  data() {
    return {
      dataList: [],
      height: '500px'
    }
  },
  methods: {
    onClickLeft() {
      location.href = 'javascript:history.back();'
      // Toast("返回");
    },
    onClickRight() {
      // Toast("按钮");
    },
    //获取详情
    getData() {
      myTaskDetail(this.$route.params.reportId).then((res) => {
        // console.log(res.data.data)
        this.dataList = res.data.data
      })
    },
    //计算总钱数
    computedAll() {
      if (this.list.length !== 0) {
        return this.list[0].totalPrice
      } else {
        return 0
      }
    },
    //计算每一条的日期
    computedTime(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    },
    //计算状态
    computedStatus(auditStatus) {
      switch (auditStatus) {
        case 0:
          return '待审核'
        case 1:
          return '审核通过'
        case 2:
          return '审核不通过'
        case 3:
          return '甄别结束'
      }
    },
    //计算颜色
    tipsColor(auditStatus) {
      switch (auditStatus) {
        case 0:
          return '#333333'
        case 1:
          return '#52C41A'
        case 2:
          return '#FAAD14'
        case 3:
          return '#FAAD14'
      }
    },
    //预览图片
    preImg(src) {
      ImagePreview([src])
    }
  },
  created() {
    this.getData()
  },
  mounted() {
    this.height = window.innerHeight - 75 - 25 + 'px'
    // if (document.querySelector(".topBox")) {
    //   this.height =
    //     window.innerHeight -
    //     44 -
    //     10 -
    //     document.querySelector(".topBox").clientHeight +
    //     "px";
    // } else {
    //   this.height = window.innerHeight - 44 - 65 + "px";
    // }
  }
}
</script>
<style lang="less" scoped>
@vv: 7.5vw;
.image {
  width: 540 / @vv;
  height: 300 / @vv;
  padding: 4 / @vv;
  border: 1px solid #ccc;
  background: #f5f5f5;
  border-radius: 8 / @vv;
}
/deep/ .van-hairline--bottom::after {
  border-bottom-width: 0px;
}
.topBox {
  background: #24adfe;
  color: #ffffff;
  position: relative;
  padding: 20px 96px 10px 15px;
  line-height: 30px;
  .title {
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 5px;
  }
  .content {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .tips {
    height: 30px;
    width: 96px;
    line-height: 30px;
    border-radius: 15px 0 0 15px;
    text-align: center;
    font-size: 12px;
    position: absolute;
    top: 20px;
    right: 0;
    background: #fffbe6;
    color: #fbba37;
  }
}
.card {
  padding: 10px 6.7% 20px 6.7%;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 16px;
  color: #666666;
  .title {
    margin-bottom: 20px;
  }
}
.container {
  position: relative;
  padding-left: 30px;
  .warning {
    position: absolute;
    left: 0px;
    top: -1px;
  }
}
.imgBox {
  height: 160px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 2px;
  .inner {
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 4px;
    background: #f5f5f5;
    height: 160px;
  }
}
.btnBox {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  height: 75px;
  opacity: 0.98;
  box-shadow: 0px 6px 28px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    height: 44px;
    width: 92%;
    border-radius: 22px;
    border: 1px solid #cccccc;
    color: #666666;
  }
}
</style>